import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

import { Route, Redirect } from 'react-router-dom'
import { auth } from '../lib/firebase'
import LoadingSpinner from './LoadingSpinner'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [user, isLoading, error] = useAuthState(auth)

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoading) return <LoadingSpinner />
        if (!user) return <Redirect to="/login" />
        return <Component {...props} />
      }}
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }) => {
  const [user, isLoading, error] = useAuthState(auth)

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoading) return <LoadingSpinner />
        if (user) return <Redirect to="/dashboard" />
        return <Component {...props} />
      }}
    />
  )
}

export { PrivateRoute, PublicRoute }
