import { BrowserRouter, Switch, Route } from "react-router-dom";
import Checkout from "./components/tip_ui/Checkout";
import Success from "./components/tip_ui/success/Success";
import Cancel from "./components/tip_ui/error/Cancel";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { PrivateRoute, PublicRoute } from "./utils/Auth";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/tip/:user_id" exact component={Checkout} />
        <Route path="/success/:amount" exact component={Success} />
        <Route path="/failed" exact component={Cancel} />
        <PublicRoute path="/login" exact component={Login} />
        <PublicRoute path="/register" exact component={Register} />
        <PrivateRoute path="/dashboard" exact component={Dashboard} />
        <PublicRoute path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
