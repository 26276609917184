import { ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db as database } from "../../../lib/firebase";
import SuccessIcon from "./check.svg";
import "./Success.css";

const Success = () => {
  const [CounterTime, setCounterTime] = useState(5);
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userdata"));
    if (userData) {
      console.log({ userData });
      userData = {
        ...userData,
        paid_status: true,
      };
      // fetch user data fromthe user collection
      const dbRef = ref(database, `Transactions/${userData.userId}`);
      set(dbRef, userData);

      // redirect to home page
      setTimeout(function () {
        window.location.replace("/");
      }, 5000);
    } else {
      window.location.replace("/");
    }

    let couter = setInterval(() => {
      setCounterTime(CounterTime - 1);
    }, 1000);

    console.log({ couter });
    return () => clearInterval(couter);
  });
  const { amount } = useParams();
  return (
    <div className="success px-3">
      <div className="customSuccessCard p-5">
        <h1>Thank You</h1>
        <div>
          <img src={SuccessIcon} className="checkmark" alt="success logo" />
        </div>
        <h1 className="text-dark">$ {Number(amount).toFixed(2)}</h1>
        <p className="py-3 text-uppercase">Your payment was Succesful !</p>

        <small className="text-muted">
          Page will be redirected in <b>{CounterTime}</b> Seconds
        </small>
      </div>
    </div>
  );
};

export default Success;
