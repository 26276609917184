import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import CustomFromGroup from '../components/forms/CustomFormGroup'
import { auth } from '../lib/firebase'
import { useHistory, useLocation } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth'

const schema = yup
  .object({
    password: yup.string().min(6).max(30).required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required()

const defaultUserValues = {
  password: '',
  confirmPassword: '',
}

const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const ResetPassword = () => {
  const history = useHistory()
  const query = useQuery()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultUserValues,
  })

  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleResetPassword = async ({ password }) => {
    setIsLoading(true)
    const actionCode = query.get('oobCode')

    try {
      await verifyPasswordResetCode(auth, actionCode)
      await confirmPasswordReset(auth, actionCode, password)
      reset()
      history.push('/login')
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container className="mt-5">
      {errorMessage && (
        <Alert onClose={() => setErrorMessage('')} dismissible>
          {errorMessage}
        </Alert>
      )}

      <h1 className="text-uppercase text-center fw-semibold mb-5 h3 ">
        Reset Password
      </h1>

      <Row>
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          <Form onSubmit={handleSubmit(handleResetPassword)}>
            <fieldset disabled={isLoading}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <CustomFromGroup
                    {...field}
                    required
                    type="password"
                    label="Password"
                    placeholder="Enter Password"
                    errorMessage={errors.password?.message}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <CustomFromGroup
                    {...field}
                    required
                    type="password"
                    label="Confirm Password"
                    placeholder="Enter Confirm Password"
                    errorMessage={errors.confirmPassword?.message}
                  />
                )}
              />

              <Button type="submit" className="w-100 text-uppercase fw-bolder">
                Update Password
              </Button>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ResetPassword
