import { Container } from 'react-bootstrap'

const LoadingSpinner = () => {
  return (
    <Container className="mt-5 text-center">
      <h1 className="text-secondary">Loading...</h1>
    </Container>
  )
}
export default LoadingSpinner
