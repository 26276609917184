import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  useSignInWithEmailAndPassword,
  useSignInWithGoogle,
  useSignInWithFacebook,
} from 'react-firebase-hooks/auth'
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons'

import { auth } from '../lib/firebase'
import CustomFromGroup from '../components/forms/CustomFormGroup'
import Divider from '../components/forms/Divider'
import useCreateUser from '../hooks/useCreateUser'
import extractUserCredentials from '../utils/extractUserCredentials'
import './styles.css'
import useErrors from '../hooks/useErrors'

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().min(6).max(30).required(),
  })
  .required()

const defaultUserValues = {
  email: '',
  password: '',
}

const Login = () => {
  const history = useHistory()
  const { createUser } = useCreateUser()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultUserValues,
  })

  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth)

  const [signInWithGoogle, googleUser, isGoogleLoading, googleError] =
    useSignInWithGoogle(auth)
  const [signInWithFacebook, facebookUser, isFacebookLoading, facebookError] =
    useSignInWithFacebook(auth)
  const { errorMessage, setErrorsProvider, clearErrors } = useErrors({
    error,
    googleError,
    facebookError,
  })
  const handleLogin = async ({ email, password }) => {
    setErrorsProvider('email')
    const userCredentials = await signInWithEmailAndPassword(email, password)
    if (userCredentials?.user) {
      reset()
    }
  }

  const handleGoogleLogin = async () => {
    setErrorsProvider('google')
    const userCredentials = await signInWithGoogle()

    const { uid, userInput, isNewUser } =
      extractUserCredentials(userCredentials)

    if (!isNewUser) return

    await createUser({
      userInput,
      createdUserId: uid,
    })
  }

  const handleFacebookLogin = async () => {
    setErrorsProvider('facebook')
    const userCredentials = await signInWithFacebook()

    const { uid, userInput, isNewUser } =
      extractUserCredentials(userCredentials)

    if (!isNewUser) return

    await createUser({
      userInput,
      createdUserId: uid,
    })
  }

  const isLoading = loading || isGoogleLoading || isFacebookLoading

  return (
    <Container className="mt-5">
      <Row>
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          {errorMessage && (
            <Alert variant="danger" onClick={() => clearErrors()} dismissible>
              {errorMessage}
            </Alert>
          )}

          <h1 className="text-uppercase text-center fw-semibold my-5 h2 ">
            Login
          </h1>
          <div>
            <FacebookLoginButton
              className="mb-3"
              onClick={handleFacebookLogin}
              disabled={isLoading}
            >
              Continue with Facebook
            </FacebookLoginButton>
            <GoogleLoginButton onClick={handleGoogleLogin}>
              Continue with Google
            </GoogleLoginButton>

            <Divider text="or" />
          </div>

          <Form onSubmit={handleSubmit(handleLogin)}>
            <fieldset disabled={isLoading}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <CustomFromGroup
                    {...field}
                    required
                    type="email"
                    label="Email"
                    placeholder="Enter Email"
                    errorMessage={errors.email?.message}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <CustomFromGroup
                    {...field}
                    required
                    type="password"
                    label="Password"
                    placeholder="Enter Password"
                    errorMessage={errors.password?.message}
                  />
                )}
              />

              <p
                className="fs-6 mb-3 forgot-password-link"
                onClick={() => window.open('/forgot-password', '_blank')}
              >
                Forgot your password?
              </p>
              <Button type="submit" className="w-100 text-uppercase fw-bolder">
                Login
              </Button>

              <Divider />
              <div>
                <Form.Text className="text-center fs-6">
                  Don't have an account?
                </Form.Text>
                <Button
                  className="text-decoration-none fw-bold"
                  variant="link"
                  onClick={() => history.push('/register')}
                >
                  Create One
                </Button>
              </div>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
