import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  useCreateUserWithEmailAndPassword,
  useSignInWithGoogle,
  useSignInWithFacebook,
} from 'react-firebase-hooks/auth'
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { auth } from '../lib/firebase'
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons'

import CustomFromGroup from '../components/forms/CustomFormGroup'

import Divider from '../components/forms/Divider'
import './styles.css'
import useCreateUser from '../hooks/useCreateUser'
import extractUserCredentials from '../utils/extractUserCredentials'
import useErrors from '../hooks/useErrors'

const schema = yup
  .object({
    fullName: yup.string().min(2).max(20),
    email: yup.string().email().required(),
    password: yup.string().min(6).max(30).required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required()

const defaultUserValues = {
  fullName: '',
  email: '',
  password: '',
  confirmPassword: '',
}

const Signup = () => {
  const history = useHistory()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultUserValues,
  })

  const { createUser } = useCreateUser()

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth)

  const [signInWithGoogle, googleUser, isGoogleLoading, googleError] =
    useSignInWithGoogle(auth)
  const [signInWithFacebook, facebookUser, isFacebookLoading, facebookError] =
    useSignInWithFacebook(auth)

  const { errorMessage, setErrorsProvider, clearErrors } = useErrors({
    error,
    googleError,
    facebookError,
  })

  const handleRegister = async data => {
    const { fullName, email, password } = data
    setErrorsProvider('email')

    const userCredentials = await createUserWithEmailAndPassword(
      email,
      password
    )
    if (!userCredentials?.user?.uid) return

    await createUser({
      userInput: { fullName, email },
      createdUserId: userCredentials.user.uid,
    })

    reset()
  }

  const handleGoogleSignup = async () => {
    setErrorsProvider('google')
    const userCredentials = await signInWithGoogle()

    const { uid, userInput, isNewUser } =
      extractUserCredentials(userCredentials)

    if (!isNewUser) return

    await createUser({
      userInput,
      createdUserId: uid,
    })
  }

  const handleFacebookSignup = async () => {
    setErrorsProvider('facebook')
    const userCredentials = await signInWithFacebook()

    const { uid, userInput, isNewUser } =
      extractUserCredentials(userCredentials)

    if (!isNewUser) return

    await createUser({
      userInput,
      createdUserId: uid,
    })
  }
  const isLoading = loading || isGoogleLoading || isFacebookLoading

  return (
    <Container className="my-5">
      <Row>
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          {errorMessage && (
            <Alert variant="danger" onClick={() => clearErrors()} dismissible>
              {errorMessage}
            </Alert>
          )}
          <h1 className="text-uppercase text-center fw-bold mb-5 h3  ">
            Register
          </h1>
          <div>
            <FacebookLoginButton
              className="mb-3"
              onClick={handleFacebookSignup}
            >
              Sign up with Facebook
            </FacebookLoginButton>
            <GoogleLoginButton onClick={handleGoogleSignup}>
              Sign up with Google
            </GoogleLoginButton>
            <Divider text="or" />
          </div>

          <Form onSubmit={handleSubmit(handleRegister)}>
            <fieldset disabled={isLoading}>
              <Controller
                name="fullName"
                control={control}
                render={({ field }) => (
                  <CustomFromGroup
                    {...field}
                    required
                    label="Full Name"
                    placeholder="Enter Full Name"
                    errorMessage={errors.fullName?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <CustomFromGroup
                    {...field}
                    required
                    type="email"
                    label="Email"
                    placeholder="Enter Email"
                    errorMessage={errors.email?.message}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <CustomFromGroup
                    {...field}
                    required
                    type="password"
                    label="Password"
                    placeholder="Enter Password"
                    errorMessage={errors.password?.message}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <CustomFromGroup
                    {...field}
                    required
                    type="password"
                    label="Confirm Password"
                    placeholder="Enter Confirm Password"
                    errorMessage={errors.confirmPassword?.message}
                  />
                )}
              />

              <Button type="submit" className="w-100 text-uppercase fw-bolder">
                Register
              </Button>

              <Divider />

              <div className="d-flex align-items-center ">
                <Form.Text className="text-center fs-6">
                  Already have an account?
                </Form.Text>
                <Button
                  variant="link"
                  className="text-decoration-none fw-bold"
                  onClick={() => history.push('/login')}
                >
                  Login
                </Button>
              </div>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Signup
