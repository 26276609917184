import { useEffect, useState } from "react";
import defaultImage from "./housekeeper.png";
import "./Checkout.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import {
  getStorage,
  ref as StorageRef,
  getDownloadURL,
} from "firebase/storage";
import { onValue, ref } from "firebase/database";
import { app, db as database } from "../../lib/firebase";

const storage = getStorage(app, "gs://housekeeper-feb54.appspot.com/");

const getStripe = () => {
  return loadStripe(process.env.REACT_APP_STRIPE_KEY);
};

const Checkout = () => {
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isImageLoad, setImageLoad] = useState(false);
  const [customTip, setcustomTip] = useState(false);
  const [CustomTipValue, setCustomTipValue] = useState("");
  const [DefaultTipValue, setDefaultTipValue] = useState("");
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    description: "",
    image_path: "",
    company: "",
    location: "",
  });
  const [Images, setImages] = useState(null);

  const { user_id } = useParams();

  // Custom tip modal
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setcustomTip(false);
    setCustomTipValue("");
    setShowModal(false);
  };
  const handleShow = () => {
    setShowModal(true);
    setcustomTip(true);
    setDefaultTipValue("");
  };

  // this is to reset the values once page reloads or back browser
  useEffect(() => {
    setLoading(true);
    window.addEventListener("focus", function () {
      setDefaultTipValue("");
      setCustomTipValue("");
      setcustomTip(false);
    });

    // fetch user data fromthe user collection
    const dbRef = ref(database, `Users/${user_id}`);
    onValue(dbRef, async (data) => {
      let fetchedData = data.val();

      if (fetchedData !== null) {
        // set the fetched data to to state
        setUserData((oldData) => {
          return {
            ...oldData,
            ...fetchedData,
          };
        });

        // Image Load section
        setImageLoad(true);
        let imgUrl = userData.image_path;
        if (imgUrl !== "") {
          let stgRef = StorageRef(storage, `${imgUrl}`);

          getDownloadURL(stgRef).then((url) => {
            setImages(url);
          });

          setImageLoad(false);
        } else {
          setImages(defaultImage);
          setImageLoad(false);
        }

        setLoading(false);
      } else {
        window.location.href = "/login";
      }
    });
  }, [user_id]);

  const item = [
    {
      tip: 2,
      price: process.env.REACT_APP_2_TIP,
      quantity: 1,
    },
    {
      tip: 5,
      price: process.env.REACT_APP_5_TIP,
      quantity: 1,
    },
    {
      tip: 10,
      price: process.env.REACT_APP_10_TIP,
      quantity: 1,
    },
  ];

  const selectTip = () => {
    let price, itemSelected;

    // if a user selects a default value, we open the custom tip modal with that value
    if ((CustomTipValue === "") & (DefaultTipValue !== "")) {
      price = Number(DefaultTipValue);

      itemSelected = item.filter((i) => {
        return i.tip === price;
      });
      redirectToCheckout(
        {
          price: itemSelected[0].price,
          quantity: 1,
        },
        price
      );
    } else {
      price = Number(CustomTipValue);
      redirectToCheckout(
        {
          price: process.env.REACT_APP_CUSTOM_TIP,
          quantity: price * 100,
        },
        price
      );
    }

    // save data to localstorage
    const data = {
      timestamp: new Date(),
      amount: price,
      currency: "CAD",
      paid_status: false,
      userId: user_id,
    };

    localStorage.setItem("userdata", JSON.stringify(data));
    handleClose();
  };

  const redirectToCheckout = async (itm, amount) => {
    setLoading(true);
    const checkoutOptions = {
      lineItems: [itm],
      mode: "payment",
      successUrl: `${window.location.origin}/success/${amount}`,
      cancelUrl: `${window.location.origin}/`,
    };
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);
    if (error) setStripeError(error.message);
    setLoading(false);
  };

  if (stripeError) alert(stripeError);

  return (
    <>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minWidth: "100vw", minHeight: "100vh" }}
        >
          <div className="spinner-grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="container my-5 text-center">
            <div className="text-center">
              <h3 className="text-muted"> Tip Housekeeper</h3>
              {userData.company === "" ? null : (
                <p className="text-center mb-3 px-4">{userData.company}</p>
              )}
            </div>
            <div className="d-flex justify-content-center my-4">
              <div className="imgCon">
                <img
                  src={
                    isImageLoad ? (
                      <div className="w-100 h-100 d-flex justify-content-center">
                        <div className="spinner-grow" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      Images
                    )
                  }
                  alt="housekeeper"
                />
              </div>
            </div>
            <div>
              <p className="text-center mb-3 px-4">
                Hi, I am {userData.fullName}{" "}
                {userData.location === ""
                  ? null
                  : `from the ${userData.location}`}
                .{" "}
                {userData.description === ""
                  ? `Thank you for
            your support. Do you want to leave a Tip?`
                  : userData.description}
              </p>
              {/* <p className="para">
            {userData.description === ""
              ? `Do you want to leave a Tip?`
              : userData.description}
          </p> */}

              {customTip ? null : (
                <div
                  className={
                    customTip
                      ? "text-center mt-5 d-flex justify-content-center"
                      : "text-center mt-5 d-flex justify-content-center gap-3 flex-wrap"
                  }
                >
                  <div className="button">
                    <input
                      type="radio"
                      id="a25"
                      name="value_select"
                      checked={DefaultTipValue === 2 ? true : false}
                      onChange={() => {
                        setDefaultTipValue(2);
                      }}
                    />
                    <label className="btn btn-default" htmlFor="a25">
                      $ 2.00
                    </label>
                  </div>
                  <div className="button">
                    <input
                      type="radio"
                      id="a50"
                      name="value_select"
                      checked={DefaultTipValue === 5 ? true : false}
                      onChange={() => {
                        setDefaultTipValue(5);
                      }}
                    />
                    <label className="btn btn-default" htmlFor="a50">
                      $ 5.00
                    </label>
                  </div>
                  <div className="button">
                    <input
                      type="radio"
                      id="a75"
                      name="value_select"
                      checked={DefaultTipValue === 10 ? true : false}
                      onChange={() => {
                        setDefaultTipValue(10);
                      }}
                    />
                    <label className="btn btn-default" htmlFor="a75">
                      $ 10.00
                    </label>
                  </div>
                </div>
              )}
              {customTip ? null : (
                <>
                  <p
                    className="py-3 mb-5 d-block"
                    style={{ color: "#dc3545", fontSize: "15px" }}
                    onClick={handleShow}
                  >
                    Custom Amount
                  </p>
                  <div
                    className="px-3 w-100 py-3"
                    style={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      zIndex: 9999,
                    }}
                  >
                    <Button
                      className="text-center w-100"
                      variant="danger"
                      onClick={selectTip}
                      disabled={
                        customTip ? true : DefaultTipValue !== "" ? false : true
                      }
                    >
                      Pay Now
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Custom tip Modal */}
          <Modal
            autoFocus={false}
            show={showModal}
            onHide={handleClose}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton onHide={handleClose}>
              <Modal.Title>Enter Custom Amount</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-3 d-flex flex-column justify-content-center gap-2">
              <CurrencyInput
                className="customTipInput"
                autoFocus={true}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                contextMenu="hidden"
                placeholder="$0.00"
                min="0"
                max="1000"
                inputMode="decimal"
                pattern="[0-9]*"
                prefix={"$"}
                value={CustomTipValue}
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value, name) => {
                  setCustomTipValue(value > 1000 ? 1000 : value);
                }}
              />

              <small className="text-muted text-center">
                $1000 is the maximum amount
              </small>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                className="w-100"
                disabled={CustomTipValue > 0 ? false : true}
                onClick={selectTip}
              >
                Pay Now
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default Checkout;
