import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth'
import CustomFromGroup from '../components/forms/CustomFormGroup'
import { auth } from '../lib/firebase'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required()

const defaultUserValues = {
  email: '',
}

const ForgotPassword = () => {
  const history = useHistory()
  const [isShownResetPasswordMessage, setIsShownResetPasswordMessage] =
    useState(false)
  const [email, setEmail] = useState('')
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultUserValues,
  })

  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth)
  const [errorMessage, setErrorMessage] = useState(error?.message || '')

  const handleSendResetPasswordEmail = async ({ email }) => {
    const hasResetPasswordEmailSent = await sendPasswordResetEmail(email)

    if (!hasResetPasswordEmailSent) return

    reset()

    setEmail(email)
    setIsShownResetPasswordMessage(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (isShownResetPasswordMessage) {
        window.location.href = '/login'
      }
    }, 1000 * 10)
  }, [isShownResetPasswordMessage, history])

  useEffect(() => {
    setErrorMessage(error?.message)
  }, [error?.message])

  return (
    <Container className="mt-5">
      {isShownResetPasswordMessage && (
        <Alert
          onClose={() => setIsShownResetPasswordMessage(false)}
          dismissible
          className="mb-5"
          style={{ maxWidth: '500px', margin: '0 auto' }}
        >
          Please check your email <b>{email}</b> Check your spam folder if you
          don't see your reset email.
        </Alert>
      )}

      {errorMessage && (
        <Alert onClose={() => setErrorMessage('')} dismissible>
          {errorMessage}
        </Alert>
      )}

      <h1 className="text-uppercase text-center fw-semibold mb-5 h3 ">
        Forgot Password
      </h1>

      <Row>
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          <Form onSubmit={handleSubmit(handleSendResetPasswordEmail)}>
            <fieldset disabled={sending}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <CustomFromGroup
                    {...field}
                    required
                    type="email"
                    label="Email"
                    placeholder="Enter Email"
                    errorMessage={errors.email?.message}
                  />
                )}
              />

              <Button type="submit" className="w-100 text-uppercase fw-bolder">
                Send Email
              </Button>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ForgotPassword
