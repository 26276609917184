const Divider = ({ text, className = '' }) => {
  const classes = className + text ? ' d-flex align-items-center gap-3' : ''

  return (
    <div className={`mt-4 mb-3 ${classes}`}>
      <hr className="w-100  border-2 border-bottom border-secondary" />
      {text && (
        <div
          style={{ fontSize: '12px' }}
          className="fw-semibold text-uppercase"
        >
          {text}
        </div>
      )}
      {text && (
        <hr className="w-100  border-2 border-bottom border-secondary" />
      )}
    </div>
  )
}

export default Divider
