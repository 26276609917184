import { useState, useEffect, useReducer } from 'react'

const defaultErrors = {
  facebook: { errorMessage: '', errorCode: null },
  google: { errorMessage: '', errorCode: null },
  email: { errorMessage: '', errorCode: null },
}

const errorsReducers = (state, action) => {
  switch (action.type) {
    case 'facebook':
      return {
        ...defaultErrors,
        facebook: { errorMessage: action.message, errorCode: action.code },
      }
    case 'google':
      return {
        ...defaultErrors,
        google: { errorMessage: action.message, errorCode: action.code },
      }
    case 'email':
      return {
        ...defaultErrors,
        email: { errorMessage: action.message, errorCode: action.code },
      }
    case 'clear':
      return defaultErrors

    default:
      return { ...defaultErrors }
  }
}

const errorsCodes = {
  'auth/email-already-exists': `This email address is already in use.`,
  'auth/email-already-in-use': `This email address is already in use.`,
  'auth/account-exists-with-different-credential': `This email address is already in use.`,
}

const useErrors = ({ error, googleError, facebookError }) => {
  const errors = { email: error, facebook: facebookError, google: googleError }

  const [state, dispatch] = useReducer(errorsReducers, defaultErrors)

  const [errorsProvider, setErrorsProvider] = useState(null)

  const errorCode = errors[errorsProvider]?.code
  const errorMessage = errorsCodes[errorCode]
    ? errorsCodes[errorCode]
    : errors[errorsProvider]?.message

  useEffect(() => {
    if (errorsProvider) {
      dispatch({
        type: errorsProvider,
        message: errorMessage,
        code: errorCode,
      })
    }
  }, [errorCode, errorMessage, errorsProvider])

  const clearErrors = () => {
    dispatch({ type: 'clear' })
  }

  return {
    errorMessage: state[errorsProvider]?.errorMessage,
    setErrorsProvider,
    clearErrors,
  }
}

export default useErrors
