import { forwardRef } from 'react'

import Form from 'react-bootstrap/Form'

const CustomFromGroup = forwardRef(
  (
    {
      label,
      type = 'text',
      isRequired,
      placeholder,
      errorMessage,
      onChange,
      ...rest
    },
    ref
  ) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label className=" fw-semibold">
          {label}
          {rest.required && <span className="text-danger  ms-1">*</span>}
        </Form.Label>

        <div className="">
          <Form.Control
            {...rest}
            type={type}
            ref={ref}
            placeholder={placeholder}
            onChange={onChange}
          />
          <Form.Text className={`w-100 ${errorMessage ? 'text-danger' : ''}`}>
            {errorMessage}
          </Form.Text>
        </div>
      </Form.Group>
    )
  }
)

export default CustomFromGroup
