import { getAdditionalUserInfo } from 'firebase/auth'

const extractUserCredentials = userCredentials => {
  if (!userCredentials?.user) return {}

  const { user } = userCredentials
  const { isNewUser } = getAdditionalUserInfo(userCredentials)
  const { uid, displayName, email } = user

  return {
    uid,
    userInput: {
      fullName: displayName || 'Anonymous',
      email,
    },
    isNewUser,
  }
}

export default extractUserCredentials
