import "./Cancel.css";
import { useEffect, useState } from "react";
import FailedIcon from "./error.svg";
import { Button } from "react-bootstrap";

const Cancel = () => {
  const [CounterTime, setCounterTime] = useState(7);
  useEffect(() => {
    setTimeout(function () {
      window.location.replace("/");
    }, 7000);
    const couter = setInterval(() => {
      setCounterTime(CounterTime - 1);
    }, 1000);
    return () => clearInterval(couter);
  });

  return (
    <div className="cancel">
      <div className="cancelDiv">
        <div className="customCancelCard p-4">
          <div className="d-flex justify-content-center">
            <img src={FailedIcon} className="error" alt="error logo" />
          </div>
          <h1 className="">Error</h1>

          <p className="pt-3 text-uppercase"> Oh No, Payment Failed !</p>
          <small className="d-block">Please Try Again</small>
          <br />
          <div className="d-flex justify-content-center py-2">
            <Button
              variant="danger"
              className="w-50"
              onClick={() => {
                window.location.replace("/");
              }}
            >
              Redirect Now
            </Button>
          </div>
          <small className="text-muted">
            Page will be redirected in <b>{CounterTime}</b> Seconds
          </small>
        </div>
      </div>
    </div>
  );
};

export default Cancel;
