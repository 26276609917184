import { useAuthState, useSignOut } from 'react-firebase-hooks/auth'

import { Button, Container } from 'react-bootstrap'
import { auth } from '../lib/firebase'

const Dashboard = () => {
  const [signOut] = useSignOut(auth)

  const [user] = useAuthState(auth)

  const logout = () => {
    signOut()
  }

  return (
    <Container>
      <div className="text-center mt-5">
        <h1>Welcome to your Dashboard</h1>
        <div className="my-5">
          <p className="lead">
            {user?.displayName && 'Name:'}
            {user?.displayName}
          </p>
          <p className="lead">Email: {user?.email} </p>
        </div>
        <Button variant="danger" onClick={logout}>
          Log Out
        </Button>
      </div>
    </Container>
  )
}
export default Dashboard
