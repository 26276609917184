import { ref, set } from 'firebase/database'
import { useState } from 'react'

import { db } from '../lib/firebase'

const useCreateUser = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [createdUser, setCreatedUser] = useState(null)

  const createUser = async ({ userInput, createdUserId }) => {
    setIsLoading(true)
    try {
      await set(ref(db, 'Users/' + createdUserId), userInput)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { createUser, createdUser, isLoading, error }
}

export default useCreateUser
